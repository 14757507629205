/* eslint-disable */
const script = document.createElement('script');
const vkElement = document.createElement('div');

script.setAttribute('src', '//vk.com/js/api/openapi.js?146');
script.onload = () => VK.Widgets.CommunityMessages(
	"vk_community_messages",
	144806061,
	{expanded: "1",tooltipButtonText: "Есть вопрос?"},
);
script.async = true;

vkElement.id = 'vk_community_messages';

document.body.appendChild(script);
document.body.appendChild(vkElement);
