import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '48, 100, 190',
		'primary-dark': '21, 73, 164',
		'accent': '232, 142, 79',
		'accent-plus': '37, 34, 23',
	},
});
