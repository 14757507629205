import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'ул. Михайловская, д. 40',
		place: null,
		phoneNumbers: ['8 (812) 602-99-15'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinolomonosov',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/кинотеатр-кинопарк/id1410795829?ls=1&mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.kinopark',
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: 'https://yandex.ru/maps/-/CBQbI6H5-C',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
